* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: Arial, Helvetica, sans-serif;
}

header {
  background-color: #1f1958;
  display: flex;
  padding: 15px 0 15px 0;
}

nav {
  display: flex;
}

header h1 {
  font-weight: bold;
  background-color: #748dcd;
  font-size: 50px;
  color: black;
  margin: 0;
  display: inline;
  padding-left: 20px;
  padding-right: 5px;
  margin-right: 20px;
}

header section {
  padding: 0px;
  height: 40px;
}

nav ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  list-style: none;
  padding-top: 10px;
  font-size: 150%;
}

nav ul li {
  padding: 10px;
  font-weight: lighter;
  text-underline-position: under;
  color: #748dcd;
  text-decoration: none;
  box-shadow: inset 0 0 0 0 #748dcd;
  -webkit-transition: ease-out 0.4s;
  -moz-transition: ease-out 0.4s;
  transition: ease-out 0.4s;
}

nav ul li:hover {
  background: #748dcd;
  color: black;
  box-shadow: inset 0 100px 0 0 #748dcd;
  cursor: pointer;
}

header nav {
  float: right;
}

.image1 {
  background-image: url(https://wallpaperaccess.com/full/187161.jpg);
  width: 100%;
  height: 200px;
  background-size: 480px;
}

/* about me  */

.aboutme h2 {
  margin-top: 30px;
  margin-left: 30px;
  margin-bottom: 15px;
}

.aboutme p {
  text-align: left;
  margin: 30px;
  margin-top: 0px;
}

/* portfolio  */

.container-wrapper {
  display: Flex;
}

.project {
  display: block;
  height: 100%;
  width: 100%;
  padding: 5px;
}

.container {
  position: relative;
  width: 30%;
}

.image_overlay {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(116, 141, 205, 0.6);
  color: #ffffff;
  font-family: "Quicksand", sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: opacity 0.25s;
}

.image_overlay:hover {
  opacity: 1;
  transition: 0.8s;
}

/*  */
.footer {
  bottom: 0;
  position: fixed;
  right: 0;
  left: 0;
  background-color: white;
}

.footer ul {
  display: flex;
  margin-top: 10px;
  list-style-type: none;
}

.contactmeitem {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 100%;
  padding-top: 15px;
  padding-bottom: 15px;
  font-size: 225%;
  padding-right: 20px;
}

.contactmeitem a {
  color: #748dcd;
}

.contactmeitem a :hover {
  color: black;
}

.profile-picture {
  margin-top: 10px;
  margin-left: 15px;
  width: 180px;
  height: 180px;
  border-radius: 50%;
  position: fixed;
}

.main-name {
  text-decoration: none;
  color: black;
}

#contact-form {
  padding: 30px;
}

.resume {
  width: 1920px;
  height: 550px;
}
